import React, { Component } from "react";

import Header from "../common/header";
import MenuCreate from "../../components/menu-create-bag";
import ItemStore from "../../components/item-store";
import "./store-shop.scss";
import Session from "../../sandbox/session";
import { connect } from "react-redux";
import {
  GetProducts,
  GetProductsSuggestions,
  GetProductForgotten,
  GetBankList,
  GetCategories,
  GetProductsByCategory,
} from "../../reducers";
import { formatCop, getClientIp, getJSessionId } from "../../rsc/utils";
import FieldInfo from "../../components/field-info";
import ItemsCarousel from "react-items-carousel";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import DatePicker from "react-datepicker/dist/es";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import moment from "moment";
import * as Consts from "../common/constants/const";
import FilterByProducts from "../../components/filter-by-products";
import { apiOnGenerateCCPay } from "../../lib/api";
import { stringify, parse } from "flatted";
import CryptoJs from "crypto-js";
// import { Helmet } from "react-helmet";
import image_mp from '../../rsc/assets/mercado_pago.png';

const toastMixin = Swal.mixin({
  toast: true,
  icon: "success",
  title: "General Title",
  animation: false,
  position: "top-right",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});
class StoreShop extends Component {
  constructor(props) {
    super(props);
    var today = new Date();

    this.state = {
      step: 1,
      total: 0,
      envio: 0,
      contentBreakfasts: [],
      listTotal: [],
      bags: [],
      NameTitular: "",
      NameTitularValid: false,
      Card: "",
      CardValid: false,
      Expiration: "",
      ExpirationValid: false,
      CvvValid: false,
      CVV: "",
      lengthCvv: 3,
      activeItemIndex: 0,
      childrenn: [],
      storeItems: [],
      messageDelivery: "",
      detailsDelivery: "",
      remNameDelivery: Session.instance.NombreSelect,
      remEmailDelivery: Session.instance.EmailAddressSelect,
      remPhoneDelivery: Session.instance.TelefonoSelect,
      NumberDocument: "",
      remDedDelivery: true,
      destNameDelivery: Session.instance.NombreSelect,
      destPhoneDelivery: Session.instance.TelefonoSelect,
      destDirectionlDelivery: Session.instance.DireccionSelect,
      // destCodePostalDelivery: "",
      destCityDelivery: Session.instance.citySelect,
      destStateDelivery: "",
      messageDeliveryValid: false,
      detailsDeliveryValid: false,
      remNameDeliveryValid: false,
      remEmailDeliveryValid: false,
      remPhoneDeliveryValid: false,
      remDedDeliveryValid: false,
      destNameDeliveryValid: false,
      destPhoneDeliveryValid: false,
      destDirectionlDeliveryValid: false,
      // destCodePostalDeliveryValid: false,
      destCityDeliveryValid: false,
      saveDataInformation: false,
      methodsPay: [
        // {
        //   id: 0,
        //   title: "Tarjeta de crédito o débito",
        //   image:
        //     "",
        // },
        // {
        //   id: 1,
        //   title: "Cuenta corriente o ahorros",
        //   image:
        //     "",
        // },
        {
          id: 0,
          title: "Efectivo",
          image:
            "",
        },
        {
          id: 1,
          title: "En Línea -  Mercadopago",
          image:
            "",
        },
      ],
      methodPay: 1,
      NumberDocumentValid: false,
      DocumentIdentificationValid: false,
      TypeClientValid: false,
      BankAccountValid: false,
      dateDelivery: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        12,
        0,
        0
      ).setDate(new Date().getDate() + 10),
      validateForm: false,
      TypeClient: "CC",
      DocumentIdentification: "Documento Identificación",
      BankAccount: "Entidad Bancaria",
      txtBtonSuggestion: Consts.txAdd,
      ResponseUrl: "http://localhost:8000/api/transactionpse/validate/",
      CodeBank: "",
      deviceSessionId: "",
      ipAddress: "",
      onFilterProducts: false,
      timeEstimatedBigger: 2,
      hourMin: 7,
    };
  }

  changeStep = (step) => {
    this.setState({ step: parseInt(step) });
  };

  componentDidUpdate() {}

  componentDidMount() {
    this.setState({
      saveDataInformation: itemSaved('saveDataInformation')
    });
    
    if(itemSaved('saveDataInformation') === "true") {
      this.setState({
        'messageDelivery': itemSaved('messageDelivery'),
        'remNameDelivery': itemSaved('remNameDelivery') || Session.instance.NombreSelect,
        'remEmailDelivery': itemSaved('remEmailDelivery')|| Session.instance.EmailAddressSelect,
        'remPhoneDelivery': itemSaved('remPhoneDelivery') || Session.instance.TelefonoSelect,
        'destNameDelivery': itemSaved('destNameDelivery') || Session.instance.NombreSelect,
        'destPhoneDelivery': itemSaved('destPhoneDelivery') || Session.instance.TelefonoSelect,
        'destDirectionlDelivery': itemSaved('destDirectionlDelivery')|| Session.instance.DireccionSelect,
        // 'destCodePostalDelivery': itemSaved('destCodePostalDelivery'),
        'destCityDelivery': itemSaved('destCityDelivery') || Session.instance.citySelect,
        'detailsDelivery': itemSaved('detailsDelivery'),
        'messageDeliveryValid': true,
        'remNameDeliveryValid': true,
        'remEmailDeliveryValid': true,
        'remPhoneDeliveryValid': true,
        'destNameDeliveryValid': true,
        'destPhoneDeliveryValid': true,
        'destDirectionlDeliveryValid': true,
        // 'destCodePostalDeliveryValid': true,
        'destCityDeliveryValid': true,
        'detailsDeliveryValid': true
      });
    }

    function itemSaved(item){
      return localStorage.getItem(item)
    }
    // this.setState({
    //   messageDelivery: "Entregaar a tiempo",
    //   detailsDelivery: "A primera horaa",
    //   remNameDelivery: "Cristian Gaviria",
    //   remEmailDelivery: "cristian@gav1r1a.com",
    //   remPhoneDelivery: "573206782343",
    //   destNameDelivery: "Cristian GAviria",
    //   destPhoneDelivery: "3508010429",
    //   destDirectionlDelivery: "Caaalle 89 # 84b 15",
    //   destCodePostalDelivery: "111011",
    //   destCityDelivery: "Bogota",
    //   'NameTitular': "Cristian Camilo Gaviria Ovalle",
    //     'Card': "4478008103833248",
    //   'Expiration': "",
    //   'CVV': "345",
    //   'ExpirationValid': true,
    //   'CardValid': true,
    //   'NameTitularValid': true,
    //   destCodePostalDeliveryValid: true,
    //   messageDeliveryValid: true,
    //   detailsDeliveryValid: true,
    //   remNameDeliveryValid: true,
    //   remEmailDeliveryValid: true,
    //   remPhoneDeliveryValid: true,
    //   remDedDeliveryValid: true,
    //   destNameDeliveryValid: true,
    //   destPhoneDeliveryValid: true,
    //   destDirectionlDeliveryValid: true,
    //   destCityDeliveryValid: true,
    //   termsAccepted: true
    // });

  }

  updateTotal = async (list) => {
    var accum = 0;
    list.map((lst) => {
      accum = parseInt(accum) + parseInt(lst);
      return lst;
    });
    await this.setState({
      total: formatCop(accum.toString()),
    });
  };

  calculateDeliverySpend = async(list) => {
    var accum = 0;
    // const vol_weight = this.state.storeItems.map((lst) => {
    //   let vol_weight = ((lst['altura'] * lst['longitud'] * lst['anchura'])/3000)*lst['quantity'];
    //   accum = vol_weight+accum;
    //   return vol_weight;
    // });

    await this.setState({
      envio: formatCop(`${Session.instance.days_delivery*7500}`).concat( ' ~ '+accum.toFixed(2) ),
    });
  }

  changeProduct = async (bag, inc) => {
    console.warn("changeProduct", inc);
    this.addProductStore(bag, inc);
  };

  async UNSAFE_componentWillMount() {
    await this.props.GetCategories();
    await this.props.GetProductsSuggestions();
    await this.props.GetProductForgotten();

    // await this.props.GetBankList({
    //   language: "es",
    //   merchant: {
    //     apiLogin: Consts.apiPayLogin,
    //     apiKey: Consts.apiPayKey,
    //   },
    //   test: false,
    //   bankListInformation: {
    //     paymentMethod: "PSE",
    //     paymentCountry: Consts.countrySg,
    //   },
    // });
    let storeItems = localStorage.getItem("itemsshop");
    if (storeItems) storeItems = parse(storeItems);
    this.mountBreakfasts(storeItems);

    await this.setSessionId();
    await this.setIpAddress();

    this.setState({
      childrenn: this.createChildren(this.props.productsSuggestions),
    });
  }

  setSessionId = async () => {
    let sessionId = await getJSessionId();
    sessionId = sessionId + moment().unix();

    this.setState({
      deviceSessionId: CryptoJs.MD5(sessionId).toString(),
    });
  };
  setIpAddress = async () => {
    let ipAddress = await getClientIp();
    this.setState({
      ipAddress: ipAddress,
    });
  };

  mountBreakfasts = async (storeItems) => {
    if (storeItems) {
      var bagss = [];
      var listTotal = [];
      var listUpdate = [];
      let timeEstimatedBigger = this.state.timeEstimatedBigger;
      await storeItems.map((breaks) => {
        breaks["quantity"] =
          breaks["quantity"] !== undefined ? breaks["quantity"] : 1;
        var tit = breaks["price"]
          ? (breaks["price"] * breaks["quantity"]).toString()
          : "0";
        listTotal.push(tit);
        bagss.push(
          <ItemStore
            deleteProduct={this.deleteProductStore.bind(this)}
            changeProduct={this.changeProduct.bind(this)}
            bag={breaks}
          />
        );
        listUpdate.push(breaks);
        if (breaks["time_estimated"] > timeEstimatedBigger) {
          timeEstimatedBigger = breaks["time_estimated"];
          this.setState({
            timeEstimatedBigger,
          });
        }
        return breaks;
      });
      this.setState({
        storeItems: listUpdate,
        contentBreakfasts: bagss,
        listTotal: listTotal,
      });
      localStorage.setItem("itemsshop", stringify(listUpdate));
      await this.updateTotal(listTotal);
      await this.calculateDeliverySpend(listTotal)
    }
  };

  render() {
    var content;
    switch (this.state.step) {
      case 1:
        content = this.productos();
        break;
      case 2:
        content = this.stepFour();
        break;
      case 3:
        content = this.stepFive();
        break;
      default:
        break;
    }

    return (
      <div className="body-content-view">
        {/* <Helmet>
          <meta charSet="utf-8" />
          <title>Tienda a domicilo | Artesanias Gb | Hyper Super</title>
        </Helmet> */}
        <Header
          header={
            <MenuCreate
              steps={[
                { name: Consts.txProds },
                { name: Consts.txInfo },
                { name: Consts.txPayment },
              ]}
              step={this.state.step}
              changeStep={this.changeStep}
            />
          }
          store={true}
        />
        <div className=" content-view cv2">
          {content}
          <span className={"butt-ind-right"}>☞</span>
        </div>
        {/* <script type="text/javascript" src={'https://maf.pagosonline.net/ws/fp/tags.js?id='+this.state.deviceSessionId+'80200'}></script>
    <noscript>
      <iframe title="pagosoline" src={"https://maf.pagosonline.net/ws/fp/tags.js?id="+this.state.deviceSessionId+"80200"}></iframe>
    </noscript> */}
      </div>
    );
  }

  productos() {
    return (
      <div
        className={`content-store-shop ${
          window.innerWidth < 700 ? "style-pzed" : ""
        }`}
      >
        <div className="col1-store-s">
          {this.headerStep(
            Consts.txProdSugg,
            `${this.state.contentBreakfasts.length} ${
              this.state.contentBreakfasts.length === 1
                ? Consts.txProd
                : Consts.txProds
            } por comprar`,
            false,
            <ItemsCarousel
              // Placeholder configurations
              enablePlaceholder
              numberOfPlaceholderItems={1}
              minimumPlaceholderTime={500}
              placeholderItem={
                <div className="content-place-caro">{Consts.txSugg}</div>
              }
              // Carousel configurations
              numberOfCards={
                window.innerWidth > 1400 ? 3 : window.innerWidth > 800 ? 2 : 1
              }
              gutter={12}
              showSlither={true}
              firstAndLastGutter={true}
              freeScrolling={true}
              // Active item configurations
              requestToChangeActive={this.changeActiveItem}
              activeItemIndex={this.state.activeItemIndex}
              activePosition={"center"}
              chevronWidth={14}
              rightChevron={">"}
              leftChevron={"<"}
              outsideChevron={true}
              slidesToScroll={1}
            >
              {this.createChildren(
                this.props.productsFilter.length === 0
                  ? this.props.productsSuggestions
                  : this.props.productsFilter
              )}
            </ItemsCarousel>,
            this.state.onFilterProducts ? (
              <FilterByProducts
                onFilter={this.filterBarSuggestion}
                categories={this.props.categories}
              ></FilterByProducts>
            ) : (
              false
            )
          )}
          <div className="content-store">{this.state.contentBreakfasts}</div>
        </div>
        <div className="col2-store-s">
          <span className="notify-note">{Consts.txMesStore}</span>
          <span>
            {Consts.txSubTot}:{" "}
            <span className="text-price">
              <br />
              {Consts.txCurrency} {this.state.total}
            </span>
          </span>
          <span>
            {Consts.txEnvio}:{" "}
            <span className="text-price">
              <br />
              {Consts.txEnvio} {this.state.envio} kg
            </span>
          </span>
          {
            <span
              onClick={() => {
                this.changeStep(2);
              
                this.changeStep(3);
              }}
              className="button-continue"
            >
              {Consts.txPay}
            </span>
            
          }
          {
             <span
             className="ta-c cur-p"
             onClick={() => {
               document.getElementById("stepInformación").click();
             }}
           >
             {Consts.txCont}
           </span>
          }
        </div>
      </div>
    );
  }

  filterBarSuggestion = async (item) => {
    await this.props.GetProductsByCategory(item["title"]);
    this.onFilterProducts();
  };

  Remitente() {
    return (
      <div className="content-item-info">
        <div className="col1-store-s">
          {this.headerStep(Consts.txRem, "Datos de Facturación")}
          <div className="content-store">
            <FieldInfo
              pattern={/^[a-zA-Z][a-zA-Z ]{1,50}$/}
              state="remNameDelivery"
              error={
                this.state.validateForm ? this.state.remNameDeliveryValid : true
              }
              editValue={this.handleChange}
              value={this.state.remNameDelivery}
              place={"Nombre"}
            />
            <FieldInfo
              pattern={/^[0-9]{10}$/}
              state="remPhoneDelivery"
              error={
                this.state.validateForm
                  ? this.state.remPhoneDeliveryValid
                  : true
              }
              editValue={this.handleChange}
              value={this.state.remPhoneDelivery}
              place={"Telefono"}
            />
            <FieldInfo
              pattern={/[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$/}
              state="remEmailDelivery"
              error={
                this.state.validateForm
                  ? this.state.remEmailDeliveryValid
                  : true
              }
              value={this.state.remEmailDelivery}
              editValue={this.handleChange}
              place={"Correo Electronico"}
            />
            <label for="remember">¿Quieres recordar los datos?</label>
            <input onClick={()=>{this.onPressSaveInformation()}} type="checkbox" id="remember" name="remember" checked={JSON.parse(localStorage.getItem('saveDataInformation'))}></input>
            {/* <ItemCheck state={"remDedDelivery"} value={this.state.remDedDelivery} editValue={this.handleChange} description={Consts.txRem+" visible para el destinatario?"} title={"Dedicatoria"} /> */}

            {
              <div
                onClick={() => {
                  document.getElementById("stepPago").click();
                }}
                className="col2-store-s"
              >
                <span className={"note-fields-obli"}>
                  Nota: Todos los campos son obligatorios
                </span>
                <span className="button-continue">{Consts.txCont}</span>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }

  onPressSaveInformation() {
    this.setState({
      saveDataInformation: !this.state.saveDataInformation 
    });
    localStorage.setItem('saveDataInformation', !this.state.saveDataInformation);
  }

  Destinatario() {
    return (
      <div className="content-item-info">
        <div className="col1-store-s">
          {this.headerStep(Consts.txDest, Consts.txAuxDest)}
          <div className="content-store">
            <FieldInfo
              pattern={/^[a-zA-Z][a-zA-Z ]{1,50}$/}
              error={
                this.state.validateForm
                  ? this.state.destNameDeliveryValid
                  : true
              }
              editValue={this.handleChange}
              value={this.state.destNameDelivery}
              state={"destNameDelivery"}
              place={"Nombre"}
            />
            <FieldInfo
              pattern={/^[0-9]{10}$/}
              error={
                this.state.validateForm
                  ? this.state.destPhoneDeliveryValid
                  : true
              }
              editValue={this.handleChange}
              value={this.state.destPhoneDelivery}
              state={"destPhoneDelivery"}
              place={"Telefono"}
            />
            <FieldInfo
              pattern={/[A-Z* a-z* 0-9* -.,#/]/}
              error={
                this.state.validateForm
                  ? this.state.destDirectionlDeliveryValid
                  : true
              }
              editValue={this.handleChange}
              // endEditValue={this.changeDirection}
              value={this.state.destDirectionlDelivery}
              state={"destDirectionlDelivery"}
              place={"Dirección"}
            />
            <FieldInfo
              pattern={/^[a-zA-Z][a-zA-Z ]{1,50}$/}
              error={this.state.validateForm ? this.state.destCityDeliveryValid : true}
              editValue={this.handleChange}
              value={this.state.destCityDelivery}
              state={"destCityDelivery"}
              place={"Ciudad"}
            />
            {/* <FieldInfo
              pattern={/(\d{5}(\d{4})?)/}
              error={this.state.validateForm ? this.state.destCodePostalDeliveryValid : true}
            editValue={this.handleChange}
            value={this.state.destCodePostalDelivery}
            state={"destCodePostalDelivery"}
            place={"Codigo postal"}
          /> */}
          </div>
          <span className="msg-dest">{Consts.txMsgDest}</span>
        </div>
      </div>
    );
  }

  Informacion() {
    return (
      <div className="content-item-info">
        {this.headerStep("Informacion de envio", "Detalles de elaboración")}
        <div className="content-store">
          <FieldInfo
            pattern={/[A-Z* a-z* 0-9* -.,/ ]/}
            state={"messageDelivery"}
            error={
              this.state.validateForm ? this.state.messageDeliveryValid : true
            }
            editValue={this.handleChange}
            value={this.state.messageDelivery}
            place={"Mensaje"}
          />
          <FieldInfo
            pattern={/[A-Z* a-z* 0-9* -.,/ ]/}
            state={"detailsDelivery"}
            error={
              this.state.validateForm ? this.state.detailsDeliveryValid : true
            }
            editValue={this.handleChange}
            value={this.state.detailsDelivery}
            place={"Detalles de entrega/elaboración"}
          />
          <span className="msg-dest">
            Recuerda que sera el mensaje para los productos con excepcion de los
            personalizados anteriormente
          </span>
        </div>
      </div>
    );
  }

  stepFour() {
    return (
      <div
        className={`content-informacion ${
          window.innerWidth < 700 ? "style-pzed" : ""
        }`}
      >
        {this.Informacion()}
        {this.Destinatario()}
        {this.Remitente()}
      </div>
    );
  }

  checkNumberCard = (title, value) => {
    var creditCardValidator = {};
    creditCardValidator.cards = {
      mc: "5[1-5][0-9]{14}",
      vi: "4(?:[0-9]{12}|[0-9]{15})",
      ax: "3[47][0-9]{13}",
      dc: "3(?:0[0-5][0-9]{11}|[68][0-9]{12})",
      di: "6011[0-9]{12}",
      jcb: "(?:3[0-9]{15}|(2131|1800)[0-9]{11})",
      er: "2(?:014|149)[0-9]{11}",
    };
    value = String(value).replace(/[- ]/g, ""); //ignore dashes and whitespaces
    var ccType = "mc";
    var cardinfo = creditCardValidator.cards,
      results = [];
    if (ccType) {
      var expr = "^" + cardinfo[ccType.toLowerCase()] + "$";
      var cardValid = expr ? !!value.match(expr) : false;
    }
    for (var p in cardinfo) {
      if (value.match("^" + cardinfo[p] + "$")) {
        results.push(p);
      }
    }
    var cardNick;
    switch (results.join("|")) {
      case "vi":
        cardNick = "VISA";
        cardValid = true;
        break;
      case "mc":
        cardNick = "MASTERCARD";
        cardValid = true;
        break;
      case "ax":
        cardNick = "AMERICANEXPRESS";
        cardValid = true;
        break;
      case "dc":
        cardNick = "DINERSCLUB";
        cardValid = true;
        break;
      case "di":
        cardNick = "DISCOVER";
        cardValid = true;
        break;
      case "jcb":
        cardNick = "JAPANCREDITBUREAU";
        cardValid = true;
        break;
      case "er":
        cardNick = "ER";
        cardValid = true;
        break;
      default:
        cardNick = "";
        cardValid = false;
    }
    this.setState({
      lengthCvv: results.join("|") === "ax" ? 4 : 3,
      typeCard: cardNick,
      cardNick:
        cardNick.length > 1
          ? cardNick + "-" + value.substring(value.length - 4, value.length)
          : "",
      CardValid: cardValid,
      remembermethod: false,
    });
    this.handleChange(title, value.replace(/[- ]/g, ""));
  };

  expirationDate = (title, date) => {
    var expiration = date.replace(/ /g, "").replace("/", "/01/");
    expiration = Date.parse(expiration);
    var dateNow = new Date();
    dateNow = new Date(dateNow.getUTCFullYear(), dateNow.getMonth(), "01");
    dateNow = Date.parse(dateNow);
    this.setState({
      ExpirationValid: expiration >= dateNow,
    });
    this.handleChange(title, date);
  };

  selectBank = (title, value, patt) => {
    let code = "";
    let item = this.props.setBanks.filter((vl) => vl.description === value);
    code = item[0]["pseCode"];
    this.setState({
      CodeBank: code,
    });
    this.handleChange(title, value, patt);
  };

  changeDirection = (title, value, pattern) => {
    axios
      .post(
        "http://visor.codigopostal.gov.co/RestService472/CodigoPostal/EntryOrdinary",
        {
          token: "45de0386-da54-42f9-9dc5-64187257f7ca",
          departamento: "Bogota, D.C.",
          municipio: "Bogota D.C.",
          dvp: "11001000",
          direccion: value,
        }, {headers: {"Access-Control-Allow-Origin": "*"}}
      )
      .then((response) => {
        if (response["data"]) {
          this.handleChange(
            "destCodePostalDelivery",
            response["data"]["cp"],
            /(\d{5}(\d{4})?)/
          );
        }
      })
      .catch((er) => {
        console.error("Error", er);
      });
    this.handleChange(title, value, pattern);
  };

  handleChange = (title, value, pattern) => {
    if (pattern !== undefined) {
      this.setState({
        [title + "Valid"]: pattern.test(value),
      });
    }
    this.setState(
      {
        [title]: value,
      },
      () => {}
    );
    localStorage.setItem([title], value)
    this.render();
  };

  handleChangeDate = (date) => {
    this.setState({
      dateDelivery: date,
    });
  };

  changeTextBtnSugg = () => {
    if (this.state.txtBtonSuggestion === Consts.txAdd) {
      this.setState({ txtBtonSuggestion: "Cambiar" });
    }
  };

  offChangeTextBtnSugg = () => {
    if (this.state.txtBtonSuggestion === "Cambiar") {
      this.setState({ txtBtonSuggestion: Consts.txAdd });
    }
  };

  changeMethodPay = (method) => {
    this.setState({
      methodPay: method,
    });
  };

  stepFive() {
    let currentComponent = this;
    let contentProductsDelivery = [];
    if (this.state.storeItems)
      this.state.storeItems.map((itms) => {
        let imageItem = itms["images"][0]
          ? itms["images"][0]["drive_key"]
          : "images/im-n-disp.png";
        return contentProductsDelivery.push(
          <div className={"cnt-item-res"}>
            {itms["images"] &&
            itms["images"].length > 0 &&
            itms["images"][0]["drive_key"] &&
            itms["images"][0]["drive_key"].split("mp4").length > 1 ? (
              <video
                className={`cnt-image-res`}
                controls
                src={`${Consts.urlStatic}${imageItem}`}
              ></video>
            ) : (
              <div
                className={"cnt-image-res"}
                style={{
                  backgroundImage: `url(${Consts.urlStatic}${imageItem})`,
                }}
              ></div>
            )}
            <span className={"title-min-targ"}>{itms.title}</span>
          </div>
        );
      });

    let productForgotten = { title: "No products available", price: "0" };
    console.log("FOrgotten 1 ", this.props); 

    if (this.props.productForgotten) {
      console.log("FOrgotten", this.props.productForgotten)
      productForgotten = this.props.productForgotten;
    }
    let daysMore = 1 ;
    let date = new Date();
    let newHourMin = 7;

    newHourMin = 7 + parseInt(this.state.timeEstimatedBigger);
    if (newHourMin > 22) {
      //"The time more 1 day", Math.round(newHourMin /21),newHourMin /21,newHourMin
      daysMore = Math.round(newHourMin / 21);
      newHourMin = parseInt(this.state.timeEstimatedBigger) - daysMore * 13;
    }

    if (typeof this.state.dateDelivery !== "number") {
      if (this.state.dateDelivery.getDate() > date.getDate() + daysMore) {
        newHourMin = 7;
      }
    }
    if (this.state.hourMin !== newHourMin) {
      this.setState({
        hourMin: newHourMin,
      });
    }

    let dateDelivery = date.setDate(date.getDate() + parseInt(Session.instance.days_delivery));

    var imageItemForgotten = "";
    if (productForgotten["images"] && productForgotten["images"].length > 0) {
      imageItemForgotten = productForgotten["images"][0]["drive_key"];
    }
    /*
    if(date.getDate() >   typeof this.state.dateDelivery === "number" ? new Date(Math.round(this.state.dateDelivery/1000)).getDate() : this.state.dateDelivery.getDate()){
      newHourMin = 7;
    } else {
      newHourMin = this.state.hourMin;

    }
*/

    /*
    if(hoursNow > 15) {
      daysMore = 1;
      let timeLeft = 24-hoursNow +7;
      if(this.state.timeEstimatedBigger  > timeLeft) {
        let newHourMin = 7 + this.state.timeEstimatedBigger - timeLeft;

        if(newHourMin > 22) {
          daysMore =   Math.round(newHourMin /24);
          newHourMin = 7;

        }
        if(this.state.hourMin !== newHourMin) {
          this.setState({
            hourMin: newHourMin
          });
        }

      }
    }*/
    return (
      <div
        className={`content-pay ${window.innerWidth < 700 ? "style-pzed" : ""}`}
      >
        <div className={"content-header-pay3"}>
          <div
            className={`body-p-suggestion ${
              window.innerWidth < 700 ? "style-pzed" : ""
            }`}
          >
            <span className={"title-sugg"}>{Consts.txAdd} esto!</span>

            <div className={"content-item-forg"}>
              <div className={"ct-txt-forg"}>
                <span className={"txt-item-forg"}>
                  {productForgotten["title"]}
                </span>
                <span className={"txt-item-forg"}>
                  {formatCop(productForgotten["price"].toString())}
                </span>
              </div>
              <div
                onMouseOut={this.offChangeTextBtnSugg}
                onMouseOver={this.changeTextBtnSugg}
                id={"iconChangeSugg"}
                ref={(ref) => {
                  this.iconChangeSugg = ref;
                }}
                onClick={() => {
                  currentComponent.props.GetProductsSuggestions();
                  currentComponent.props.GetProductForgotten();
                }}
                className={"icon-change ic-c-forg"}
              >
                <span>Cambiar</span>
              </div>
              <div className={"ct-image-forgotten"}>
                <div
                  className={"cnt-image-res"}
                  style={{
                    width: "100%",
                    height: "8em",
                    backgroundImage: `url(${Consts.urlStatic}${imageItemForgotten})`,
                  }}
                ></div>
              </div>

              <div className={"btn-add-forgotten"}>
                <span
                  onClick={() => {
                    currentComponent.addProductStore(
                      productForgotten,
                      productForgotten.quantity + 1,
                      0
                    );
                  }}
                >
                  {this.state.txtBtonSuggestion}
                </span>
              </div>
            </div>
          </div>
          {this.headerStep(
            "Metodo de pago",
            "Seleccione un metodo de pago",
            [],
            <div>
              <div>
                <h4 className={"txt-message-header"}>
                  {Consts.txThbuy} {this.state.remNameDelivery}.{" "}
                  {Consts.txMsgBuy}
                </h4>
                <p className={"txt-mail-header"}>
                  {this.state.remEmailDelivery} es el mail de notificacion.
                </p>
              </div>
            </div>,
            null
          )}
          <span className={"title-total-3"}>
            {Consts.txTot} {this.state.total} <small>{Consts.txCurrency}</small>
          </span>
        </div>
        <p className={"txt-terms-header"}>
          <input
            checked={this.state.termsAccepted}
            value={this.state.termsAccepted}
            onClick={() => {
              this.setState({
                termsAccepted: !this.state.termsAccepted,
              });
            }}
            type="checkbox"
            name="user"
            id="fuser"
          />
          <label htmlFor="fuser">
            <span>
              He leído y acepto los{" "}
              <span
                onClick={() => {
                  Swal.fire({
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    allowOutsideClick: false,
                    title: Consts.terms,
                    html:
                      "<h2 style='text-align: center;'><strong> " +
                      Consts.terms +
                      "</strong></h2><p>&nbsp;</p>" +
                      Session.instance.country["terms_conditions"],
                  });
                }}
              >
                términos y condiciones
              </span>
              y la
              <span
                onClick={() => {
                  Swal.fire({
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    allowOutsideClick: false,
                    title: "Política de privacidad",
                    html:
                      "<h1>Privacy Policy for " +
                      Consts.txTitle +
                      "</h1>" +
                      Session.instance.country["privacy_policy"],
                  });
                }}
              >
                política de privacidad.
              </span>
            </span>
          </label>
        </p>
        <div className={"section-middle-pay"}>
          <div className={"content-data-pay"}>
            <div
              className={"content-title-mp"}
              onClick={() =>
                this.changeMethodPay(
                  this.state.methodPay === this.state.methodsPay.length - 1
                    ? 0
                    : this.state.methodPay + 1
                )
              }
            >
              <span className={"fs-1m title-methodpay"}>
                {(this.state.methodsPay[this.state.methodPay] &&
                  this.state.methodsPay[this.state.methodPay].title) ||
                  ""}
              </span>
              <div className={"icon-change"}></div>
            </div>
            {/* {this.state.methodPay === 0 ? (
              <div className={"content-data-mthd-pay"}>
                <ItemInformation
                  state="NameTitular"
                  pattern={/^[a-zA-Z][a-zA-Z ]{1,50}$/}
                  title="NameTitular"
                  lengthMax={50}
                  value={this.state.NameTitular}
                  type="text"
                  valid={
                    this.state.validateForm ? this.state.NameTitularValid : true
                  }
                  changeValue={this.handleChange}
                />
                <ItemInformation
                  state="Card"
                  title={`Card ${this.state.typeCard}`}
                  lengthMax={16}
                  value={this.state.Card}
                  type="number"
                  valid={this.state.validateForm ? this.state.CardValid : true}
                  changeValue={this.checkNumberCard}
                />
                <ItemInformation
                  state="Expiration"
                  title="Expiration Date"
                  placeholder="MM / YY"
                  value={this.state.Expiration}
                  type="text"
                  valid={
                    this.state.validateForm ? this.state.ExpirationValid : true
                  }
                  lengthMax={7}
                  changeValue={this.expirationDate}
                />
                <ItemInformation
                  state="CVV"
                  title="CVV #"
                  value={this.state.CVV}
                  lengthMax={this.state.lengthCvv}
                  type="number"
                  valid={
                    this.state.validateForm
                      ? this.state.lengthCvv === this.state.CVV.length
                      : true
                  }
                  changeValue={this.handleChange}
                />
              </div>
            ) : (
              []
            )}
            {this.state.methodPay === 1 ? (
              <div className={"content-data-mthd-pay"}>
                <ItemInformation
                  state="NameTitular"
                  pattern={/^[a-zA-Z][a-zA-Z ]{1,50}$/}
                  title="NameTitular"
                  lengthMax={50}
                  value={this.state.NameTitular}
                  type="text"
                  valid={
                    this.state.validateForm ? this.state.NameTitularValid : true
                  }
                  changeValue={this.handleChange}
                />
                <ItemInformation
                  state="BankAccount"
                  pattern={/^[a-zA-Zá-ú][a-zA-Zá-ú./`’ ]{1,50}$/}
                  title="Bank Account"
                  data={this.props.setBanks}
                  value={this.state.BankAccount}
                  typeInput="select"
                  type="text"
                  valid={
                    this.state.validateForm ? this.state.BankAccountValid : true
                  }
                  changeValue={this.selectBank}
                />
                <ItemInformation
                  state="TypeClient"
                  pattern={/^[a-zA-Zá-ú][a-zA-Zá-ú./`’ ]{1,50}$/}
                  title="Tipo de Cliente"
                  data={Consts.setTypPers}
                  value={this.state.TypeClient}
                  valid={
                    this.state.validateForm ? this.state.TypeClientValid : true
                  }
                  typeInput="select"
                  type="text"
                  changeValue={this.handleChange}
                />
                <ItemInformation
                  state="DocumentIdentification"
                  pattern={/^[a-zA-Zá-ú][a-zA-Zá-ú./`’, ]{1,150}$/}
                  title="Documento de Identificación"
                  data={Consts.setDocs}
                  value={this.state.DocumentIdentification}
                  typeInput="select"
                  type="text"
                  valid={
                    this.state.validateForm
                      ? this.state.DocumentIdentificationValid
                      : true
                  }
                  changeValue={this.handleChange}
                />
                <ItemInformation
                  state="NumberDocument"
                  pattern={/[A-Z* a-z* 0-9* -.,/ ]/}
                  title="NumberDocument"
                  lengthMax={50}
                  value={this.state.NumberDocument}
                  type="text"
                  valid={
                    this.state.validateForm
                      ? this.state.NumberDocumentValid
                      : true
                  }
                  changeValue={this.handleChange}
                />
              </div>
            ) : (
              []
            )} */}
            {this.state.methodPay === 0 ? (<div></div>) : ([])}
            {this.state.methodPay === 1 ? (<img alt="mercadopago-logo" src={image_mp}/>) : ([])}

            <span onClick={this.onGeneratePay} className="w-98 button-continue">
              {Consts.txPay}
            </span>
          </div>
          <div
            className={`${
              window.innerWidth < 700 ? "style-pzed" : ""
            } cnt-colm2-delivery`}
          >
            <span className={"title-methodpay"}>{Consts.txDateDel}</span>

            <DatePicker
              selected={this.state.dateDelivery}
              onChange={this.handleChangeDate}
              showTimeSelect
              minDate={dateDelivery}
              minTime={date.setHours(this.state.hourMin, 1)}
              maxTime={date.setHours(22, 30)}
              dateFormat="MMMM d, yyyy h:mm aa"
              timeSelected={date.setHours(7, 1)}
            />
            <span className={"c-w tit-carr title-methodpay"}>Carrito</span>
            <div className={"content-icon-truck"}>
              <div className={"content-items-truck"}>
                {contentProductsDelivery}
              </div>
            </div>
          </div>
        </div>
        <div className="content-info-pay"></div>
      </div>
    );
  }

  deleteProductStore = async (product) => {
    this.setState({
      storeItems: [],
      contentBreakfasts: [],
      listTotal: 0,
    });
    var products = [...this.state.storeItems];
    var repProd = await products.filter((p) => {
      return p["identifier"] !== product["identifier"];
    });
    const newP = repProd;

    await this.mountBreakfasts(newP);
    //this.changeStep(2);
  };

  addProductStore = (product, inc, count) => {
    var products = [...this.state.storeItems];
    var indexToEdit = null;
    // eslint-disable-next-line
    var productRepeated = products.filter((p, index) => {
      if (p["identifier"] === product["identifier"]) {
        indexToEdit = index;
        return p;
      }
    });
    if (productRepeated.length > 0) {
      if (
        productRepeated[0]["quantity"] < productRepeated[0]["quantity_store"]
      ) {
        // var prodWithoutRepeat = products.filter((p) => {return p["identifier"] !== product["identifier"]});
        productRepeated[0]["quantity"] =
          inc === false ? count : productRepeated[0]["quantity"] + inc;
        let resultado = products;
        resultado[indexToEdit] = productRepeated[0];
        products = resultado;
        toastMixin.fire({
          position: "top-end",
          icon: "success",
          title: Consts.txProdAddUpd,
          showConfirmButton: false,
          timer: 750,
        });
      } else {
        toastMixin.fire({
          position: "top-end",
          icon: "error",
          title: Consts.txProdSold,
          showConfirmButton: true,
          timer: 2000,
        });
      }
    } else {
      toastMixin.fire({
        position: "top-end",
        icon: "success",
        title: Consts.txProdAdd,
        showConfirmButton: false,
        timer: 750,
      });
      products.push(product);
    }
    this.mountBreakfasts(products);
  };

  createChildren = (products) =>
    products.map((i) => {
      return (
        <div
          onClick={() => {
            this.addProductStore(i, 1, 0);
          }}
          className={"item-car-sugg"}
        >
          <span className={"tit-acc"}>{Consts.txAdd}?</span>
          <div className={"content-item-suggestions"}>
            <div className={"content-info-suggestion"}>
              <h5>* {i.category}</h5>
              <h4>{i.title}</h4>
            </div>
            <div className={"content-info-item"}>
              <span>{formatCop(i.price.toString())}</span>
            </div>
            <div
              style={{
                backgroundImage: `url(${Consts.urlStatic}${
                  i["images"][0]
                    ? i["images"][0]["drive_key"]
                    : "images/im-n-disp.png"
                })`,
              }}
              key={i}
              className="content-item-caro "
            ></div>
          </div>
        </div>
      );
    });

  changeActiveItem = (activeItemIndex) => {
    this.setState({ activeItemIndex: activeItemIndex });
  };

  onFilterProducts = () => {
    this.setState({
      onFilterProducts: !this.state.onFilterProducts,
    });
  };

  headerStep(title, description, ct, carousel, filter) {
    return (
      <div
        className={`${window.innerWidth < 700 ? "style-pzed" : ""} header-step`}
      >
        <div className="content-title">
          {title !== "" ? (
            <span className="title-hstep">
              {title}
              {}
            </span>
          ) : (
            []
          )}
          {description ? <span className="desc-hstep">{description}</span> : []}
          {filter === false ? (
            <div
              onClick={this.onFilterProducts}
              className={"cur-p c-br btn-third"}
            >
              Filtrar por categoria
            </div>
          ) : (
            []
          )}
          {ct}
        </div>
        {filter !== false && carousel !== undefined ? (
          <div className={"content-carousel"}>{filter}</div>
        ) : (
          []
        )}
        {filter === false && carousel !== undefined ? (
          <div className="content-carousel">{carousel}</div>
        ) : (
          []
        )}
      </div>
    );
  }

  onValidateGeneralInfo = async () => {
    const { state } = this;
    let infoValidation =
      state.messageDeliveryValid &&
      state.detailsDeliveryValid &&
      state.destPhoneDeliveryValid &&
      state.destDirectionlDeliveryValid &&
      // state.destCodePostalDeliveryValid &&
      state.destCityDeliveryValid &&
      state.destNameDeliveryValid &&
      state.remNameDeliveryValid &&
      state.remEmailDeliveryValid &&
      state.remPhoneDeliveryValid;
    if (!infoValidation) {
      Swal.fire(Consts.txErr, Consts.txBadInfo, "error");
      document.getElementById("stepInformación").click();
      return false;
    }
  };

  onGeneratePay = async () => {
    this.setState({
      validateForm: true,
    });
    if ((await this.onValidateGeneralInfo()) === false) {
      return;
    }

    // Bank Account = 1
    if (!this.state.termsAccepted) {
      Swal.fire(Consts.txErr, Consts.txTerms, "error");
      return;
    }

    if (this.state.methodPay === 1) {
      this.onGenerateMercadoPay();
    }
    if (this.state.methodPay === 0) {
      this.onGenerateCashPay();
    }
    // if (this.state.methodPay === 1) {
    //   this.onGenerateBankPay();
    // }
    // // Credit Card = 0
    // if (this.state.methodPay === 0) {
    //   this.onGenerateCcPay();
    // }
  };

  onGenerateMercadoPay = async () => {
    this.onGenerateCcPay("mercadopago")
  }

  onGenerateCashPay = async () => {
    this.onGenerateCcPay("cash")
  }

  // onGenerateBankPay = async () => {
  //   const state = this.state;
  //   let bodyPay = {};

  //   let validFormPay =
  //     !state.NumberDocumentValid ||
  //     !state.DocumentIdentificationValid ||
  //     !state.TypeClientValid ||
  //     !state.BankAccountValid ||
  //     !state.NameTitularValid;
  //   if (validFormPay) {
  //     Swal.fire(Consts.txErr, Consts.txBadInfoPay, "error");
  //     return;
  //   }

  //   let totalUnformat = state.total.replace(/\./, "").replace(/^\$ /, "");
  //   //

  //   if (parseInt(totalUnformat) < 500) {
  //     Swal.fire("Error!", "El valor debe ser mayor.", "error");
  //     return;
  //   }
  //   let dateDelivery =
  //     typeof state.dateDelivery === "number"
  //       ? moment
  //           .unix(parseInt(this.state.dateDelivery.toString().slice(0, 10)))
  //           .format()
  //       : state.dateDelivery;

  //   bodyPay["infoPay"] = {
  //     total: parseInt(totalUnformat),
  //     details: state.detailsDelivery,
  //     message: state.messageDelivery,
  //     paymentMethod: "PSE",
  //     typeClient: state.TypeClient.split(" ")[0],
  //     name: state.NameTitular,
  //     codeBank: state.CodeBank,
  //     responseUrl: state.ResponseUrl,
  //     dedicatory: state.remDedDelivery,
  //     dateDelivery: dateDelivery,
  //     cardNick: "PSE-" + dateDelivery,
  //   };

  //   let directionShipping = state.destDirectionlDelivery?.split("#");
  //   if(directionShipping !== undefined)
  //     bodyPay["infoDelivery"] = {
  //       fullName: state.destNameDelivery,
  //       emailAddress: state.remEmailDelivery,
  //       contactPhone: state.destPhoneDelivery,
  //       dniNumber: state.NumberDocument,
  //       dniType: state.TypeClient.split(" ")[0],
  //       shippingAddress: {
  //         street1: directionShipping[0],
  //         street2: directionShipping[1],
  //         city: state.destCityDelivery,
  //         state: state.destStateDelivery,
  //         country: Consts.countrySg,
  //         // postalCode: state.destCodePostalDelivery,
  //         phone: state.destPhoneDelivery,
  //       },
  //     };

  //   let directionSender = state.destDirectionlDelivery?.split("#");
  //   bodyPay["infoSender"] = {
  //     fullName: state.remNameDelivery,
  //     emailAddress: state.remEmailDelivery,
  //     contactPhone: state.remPhoneDelivery,
  //     dniNumber: state.NumberDocument,
  //     dniType: state.DocumentIdentification.split(" ")[0],
  //     billingAddress: {
  //       street1: directionSender[0],
  //       street2: directionSender[1],
  //       city: state.destCityDelivery,
  //       state: state.destStateDelivery,
  //       country: Consts.countrySg,
  //       // postalCode: state.destCodePostalDelivery,
  //       phone: state.remPhoneDelivery,
  //     },
  //   };

  //   bodyPay["verification"] = {
  //     deviceSessionId: this.state.deviceSessionId,
  //     ipAddress: this.state.ipAddress,
  //     cookie: document.cookie,
  //     userAgent: window.navigator.userAgent,
  //     apiKey: Consts.apiPayKey,
  //     apiLogin: Consts.apiPayLogin,
  //     accountId: Consts.accountPay,
  //     paymentMethod: "PSE",
  //     paymentCountry: Consts.countrySg,
  //   };
  //   bodyPay["store"] = state.storeItems;

  //   apiOnGenerateBankPay(bodyPay);
  // };

  onGenerateCcPay = async (paymentMethod) => {
    const state = this.state;
    let bodyPay = {};
    let validFormPay =  
      !state.CardValid ||
      !state.ExpirationValid ||
      state.lengthCvv !== state.CVV.length;
    if (validFormPay && paymentMethod === undefined) {
      Swal.fire(Consts.txErr, Consts.txBadInfo, "error");
      return;
    }
    let totalUnformat = state.total.replace(/\./g, "").replace(/^\$ /g, "");

    if (parseInt(totalUnformat) < 500) {
      Swal.fire(Consts.txErr, Consts.txValHigh, "error");
      return;
    }

    let dateDelivery =
      typeof state.dateDelivery === "number"
        ? moment
            .unix(parseInt(this.state.dateDelivery.toString().slice(0, 10)))
            .format()
        : state.dateDelivery;

    bodyPay["infoPay"] = {
      total: totalUnformat,
      details: state.detailsDelivery,
      message: state.messageDelivery,
      paymentMethod: paymentMethod === 'cash' | paymentMethod === 'mercadopago' ? paymentMethod : /^4[0-9]{12}(?:[0-9]{3})?$/.test(state.Card)
        ? "debit"
        : "credit",
      number: state.Card,
      securityCode: state.CVV,
      expirationDate: state.Expiration,
      name: state.NameTitular,
      dedicatory: state.remDedDelivery,
      dateDelivery: dateDelivery,
      cardNick: this.state.cardNick,
    };

    let directionShipping = state.destDirectionlDelivery?.split("#");
    if(directionShipping !== undefined)
      bodyPay["infoDelivery"] = {
        fullName: state.destNameDelivery,
        emailAddress: state.remEmailDelivery,
        contactPhone: state.destPhoneDelivery,
        dniNumber: state.NumberDocument,
        dniType: state.TypeClient.split(" ")[0],
        shippingAddress: {
          street1: directionShipping[0],
          street2: directionShipping[1],
          city: state.destCityDelivery,
          state: state.destStateDelivery,
          country: Consts.countrySg,
          postalCode: "111021",
          phone: state.destPhoneDelivery,
        },
      };

    let directionSender = state.destDirectionlDelivery?.split("#");
    if(directionSender !== undefined)
      bodyPay["infoSender"] = {
        fullName: state.remNameDelivery,
        emailAddress: state.remEmailDelivery,
        contactPhone: state.remPhoneDelivery,
        dniNumber: state.NumberDocument,
        dniType: state.TypeClient.split(" ")[0],
        billingAddress: {
          street1: directionSender[0],
          street2: directionSender[1],
          city: state.destCityDelivery,
          state: state.destStateDelivery,
          country: Consts.countrySg,
          postalCode: "111021",
          phone: state.remPhoneDelivery,
        },
      };

    bodyPay["verification"] = {
      deviceSessionId: this.state.deviceSessionId,
      ipAddress: this.state.ipAddress,
      cookie: document.cookie,
      userAgent: window.navigator.userAgent,
      apiKey: Consts.apiPayKey,
      apiLogin: Consts.apiPayLogin,
      accountId: Consts.accountPay,
      paymentMethod: paymentMethod,
      paymentCountry: Consts.countrySg,
    };
    bodyPay["store"] = state.storeItems;
    apiOnGenerateCCPay(bodyPay);
  };
}

export default connect(
  (state) => ({
    products: state.setInfoData.products,
    productsSuggestions: state.setInfoData.products,
    productForgotten: state.setInfoData.productForgotten,
    setBanks: state.setInfoData.setBanks,
    categories: state.setInfoData.categories,
    productsFilter: state.setInfoData.productsFilter,
  }),
  {
    GetProducts,
    GetProductsSuggestions,
    GetProductForgotten,
    GetBankList,
    GetCategories,
    GetProductsByCategory,
  }
)(StoreShop);
